import React from 'react';
import { graphql, Link } from 'gatsby';

import { HeadingXL, Layout, SEO, HeaderLogo } from '../components';
import { Hero } from '../components/Hero';
import { Post } from '../components/Post';
import { PostList } from '../components/PostList';

const Tag = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const posts = data.tagPosts.edges;

  return (
    <>
      <SEO title={`Artículos etiquetados como ${tag}`} />
      <HeaderLogo />
      <Layout>
        <Hero>
          <Link to="/">
            <HeadingXL style={{ marginBottom: 0 }}>{tag}</HeadingXL>
          </Link>
        </Hero>
        <PostList>
          {posts.map(({ node }) => (
            <Post key={node.id} post={node} />
          ))}
        </PostList>
      </Layout>
    </>
  );
};

export default Tag;

export const query = graphql`
  query ($tag: String!) {
    tagPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          tags: { in: [$tag] }
          kind: { eq: "post" }
          draft: { in: [false, null] }
        }
      }
    ) {
      edges {
        node {
          ...PostPreview
        }
      }
    }
  }
`;
